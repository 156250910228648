/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
/* gestion des compteurs de la page home */

var n = 10; // Nombre final du compteur
var cpt = 0; // Initialisation du compteur
var duree = 2; // Durée en seconde pendant laquel le compteur ira de 0 à 10
var delta = Math.ceil((duree * 1000) / n); // On calcule l'intervalle de temps entre chaque rafraîchissement du compteur (durée mise en milliseconde)
var node =  document.getElementById("compteur1"); // On récupère notre noeud où sera rafraîchi la valeur du compteur
 
function countdown() {
  node.innerHTML = ++cpt;
  if( cpt < n ) { // Si on est pas arrivé à la valeur finale, on relance notre compteur une nouvelle fois
     setTimeout(countdown, delta);
  }
}
 
setTimeout(countdown, delta);

var n2 = 13; // Nombre final du compteur
var cpt2 = 0; // Initialisation du compteur
var duree2 = 2; // Durée en seconde pendant laquel le compteur ira de 0 à 13
var delta2 = Math.ceil((duree2 * 1000) / n2); // On calcule l'intervalle de temps entre chaque rafraîchissement du compteur (durée mise en milliseconde)
var node2 =  document.getElementById("compteur2"); // On récupère notre noeud où sera rafraîchi la valeur du compteur
 
function countdown2() {
  node2.innerHTML = ++cpt2;
  if( cpt2 < n2 ) { // Si on est pas arrivé à la valeur finale, on relance notre compteur une nouvelle fois
     setTimeout(countdown2, delta2);
  }
}
 
setTimeout(countdown2, delta2);

var n3 = 13; // Nombre final du compteur
var cpt3 = 0; // Initialisation du compteur
var duree3 = 2; // Durée en seconde pendant laquel le compteur ira de 0 à 13
var delta3 = Math.ceil((duree3 * 1000) / n3); // On calcule l'intervalle de temps entre chaque rafraîchissement du compteur (durée mise en milliseconde)
var node3 =  document.getElementById("compteur3"); // On récupère notre noeud où sera rafraîchi la valeur du compteur
 
function countdown3() {
  node3.innerHTML = ++cpt3;
  if( cpt3 < n3 ) { // Si on est pas arrivé à la valeur finale, on relance notre compteur une nouvelle fois
     setTimeout(countdown3, delta3);
  }
}
 
setTimeout(countdown3, delta3);

/* page base inclue dans toutes les pages */
/* gestion de la barre de navigation fixe en sticky Nav */
$(window).scroll(function (event) {
  // A chaque fois que l'utilisateur va scroller (descendre la page)
  var y = $(this).scrollTop(); // On récupérer la valeur du scroll vertical

  //si cette valeur > à 10 on ajouter la class
  if (y >= 10) {
    $('.navbar').addClass('fixed');
  } else {
    // sinon, on l'enlève
    $('.navbar').removeClass('fixed');
  }
});

/* bannière */
// Wrap every letter in a span
$('.ml16').each(function(){
  $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
});

anime.timeline({loop: true})
  .add({
    targets: '.ml16 .letter',
    translateY: [-100,0],
    easing: "easeOutExpo",
    duration: 1400,
    delay: function(el, i) {
      return 30 * i;
    }
  }).add({
    targets: '.ml16',
    opacity: 0,
    duration: 1000,
    easing: "easeOutExpo",
    delay: 1000
  });

